import { useDialogStore } from '~/stores/useDialogStore';

export default function useRegistration() {
  const dialogStore = useDialogStore();
  dialogStore.setHeadline('registration.headline');

  const success = ref(false);

  const isInCheckout = computed(() => {
    return useRoute().path.startsWith('/checkout/login');
  });

  async function onSuccess() {
    success.value = true;
    if (useRoute().path.startsWith('/checkout/login')) {
      await navigateTo('/checkout/address');
    }
  }
  return { dialogStore, success, onSuccess, isInCheckout };
}
